import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TopicData } from '@ysura/common';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TouchPointTopicEditDialogProps = {
  available: Array<TopicData>;
  selected: Array<TopicData>;
  isDisabled: boolean;
  onChange: (event: SyntheticEvent, value: Array<TopicData>) => void;
};

export const TopicsSelect = ({
  available,
  selected,
  isDisabled,
  onChange,
}: TouchPointTopicEditDialogProps) => {
  const { t } = useTranslation();

  const [isAnyTopicSelected, setIsAnyTopicSelected] = useState(
    !isDisabled && selected.length > 0
  );

  const isTopicSelected = (topic: TopicData | undefined): boolean => {
    return !!selected?.find(
      (selectedTopic) => selectedTopic.oid === topic?.oid
    );
  };

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    values: Array<TopicData>
  ) => {
    setIsAnyTopicSelected(values.length !== 0);
    onChange(e, values);
  };

  return (
    <>
      <EmptyTypography></EmptyTypography>
      <Autocomplete
        multiple
        fullWidth
        disableCloseOnSelect
        disableClearable
        data-testid="topic-select"
        noOptionsText="No search result"
        disabled={isDisabled}
        options={available}
        getOptionLabel={(option) => option?.name ?? 'N/A'}
        value={selected}
        isOptionEqualToValue={(option, value) => option?.oid === value?.oid}
        renderOption={(props, option) => (
          <li {...props} key={option?.id ?? ''}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={isTopicSelected(option)}
              disabled={isDisabled}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('components.touchpointManagement.selectTopics')}
          />
        )}
        onChange={handleChange}
      />
      {!isAnyTopicSelected && !isDisabled && (
        <ErrorMessage>
          {t('components.touchpointManagement.error.noTopicsErrorMessage')}
        </ErrorMessage>
      )}
    </>
  );
};

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: 'small',
  paddingLeft: theme.spacing(2),
}));

const EmptyTypography = styled(Typography)(({ theme }) => ({
  // this is here to ensure that on mobile device for edit activity the title of the autoselect is not cut.
  // Autoselect itself cannot be styled
  display: 'none',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1),
    display: 'block',
  },
}));
