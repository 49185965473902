import { NetworkStatus } from '@apollo/client';
import { Box, Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, TouchpointManagementModeData } from '@ysura/common';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AttendeeInfo, AttendeesInfoList } from '@/components/Attendee';
import { TOUCHPOINT_MANAGEMENT } from '@/config/layout';
import { useCurrentUser, useOnClickOutside } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { buildPersonFilterQuery } from '@/services/graphql/helpers';
import { useGetSearchedPersonsLazyQuery } from '@/services/graphql/hooks';
import { parsePersonConnection } from '@/services/graphql/parsers';
import { Scope } from '@/services/graphql/types';

import {
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '../SharedStyledComponents';
import { BaseFormikProps } from '../types';
import { AttendeeScopeTabs } from './AttendeeScopeTabs';
import { AttendeeSearchField } from './AttendeeSearchField';
import { AttendeeSearchResults } from './AttendeeSearchResults';
import { OrganizationSection } from './OrganizationSection';

const NUMBER_OF_PERSONS_TO_SEARCH = 20;

interface TouchpointTypeColumnProps extends BaseFormikProps {
  mode: TouchpointManagementModeData;
  activity?: ActivityData;
  dialogHeight?: number;
  isSubmitting?: boolean;
}

const transitionVariant = {
  initial: {
    height: TOUCHPOINT_MANAGEMENT.SEARCH_FIELD_HEIGHT_DESKTOP,
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: { ease: 'easeIn' },
  },
  exit: {
    height: TOUCHPOINT_MANAGEMENT.SEARCH_FIELD_HEIGHT_DESKTOP,
    transition: { ease: 'easeOut' },
  },
};

export const AttendeeColumn = ({
  getFieldProps,
  setFieldValue,
  mode,
  dialogHeight,
  touchpointValues,
  activity,
  isSubmitting = false,
}: TouchpointTypeColumnProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const searchBoxRef = useRef<HTMLDivElement>(null);

  const [searchAttendeeQuery, setSearchAttendeeQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const getAttendeeFromActivity = (activity: ActivityData | undefined) => {
    if (activity?.attendees?.[0].person) {
      return [activity.attendees[0].person];
    }

    return [];
  };

  const selectedAttendees =
    mode === 'create'
      ? touchpointValues?.attendees ?? []
      : getAttendeeFromActivity(activity);

  const selectedTouchpointType = touchpointValues?.touchpointType?.oid ?? '';
  const selectedOrganization = touchpointValues?.organization ?? null;

  const [searchPersons, { data, fetchMore, called, refetch, networkStatus }] =
    useGetSearchedPersonsLazyQuery({
      ...errorConfigBannerDialog,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (isSearchDropdownOpen) {
      const scope = tabIndex === 0 ? Scope.TERRITORY : Scope.ALL;
      const personFilterQuery = buildPersonFilterQuery([], scope);

      if (called) {
        refetch({
          search: debouncedSearchQuery,
          first: NUMBER_OF_PERSONS_TO_SEARCH,
          filter: personFilterQuery,
        });
      } else {
        searchPersons({
          variables: {
            search: debouncedSearchQuery,
            first: NUMBER_OF_PERSONS_TO_SEARCH,
            filter: personFilterQuery,
          },
        });
      }
    }
  }, [
    searchPersons,
    debouncedSearchQuery,
    isSearchDropdownOpen,
    tabIndex,
    called,
    refetch,
  ]);

  const fetchMorePersons = () => {
    const endCursor = data?.persons?.pageInfo?.endCursor ?? '';

    fetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAttendeeQuery(event.target?.value);
    debouncedFunction(event.target?.value);
  };

  const handleCloseSearch = () => {
    setIsSearchDropdownOpen(false);
  };

  // Implement this hook to close search card when click outside of card
  useOnClickOutside(searchBoxRef, handleCloseSearch);

  const handleOpenSearch = () => {
    setIsSearchDropdownOpen(true);
  };

  const handleClearSearchQuery = () => {
    setSearchAttendeeQuery('');
    setDebouncedSearchQuery('');
  };

  const handleDebounce = (value: string) => {
    setSearchAttendeeQuery(value);
    setDebouncedSearchQuery(value);
  };
  const debouncedFunction = useMemo(() => debounce(handleDebounce, 500), []);

  const hasMorePerson = data?.persons?.pageInfo?.hasNextPage ?? false;
  const personSearchResults = data?.persons
    ? parsePersonConnection(data.persons)
    : [];
  const filteredCount = data?.persons?.filteredCount ?? -1;

  const isLoadingSearch =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  return (
    <Wrapper data-testid="attendee-column-wrapper">
      {mode === 'create' && (
        <MediumStack>
          <TinyBox>
            <SectionLabel variant="subtitle2">
              {`${t('components.touchpointManagement.attendee')} (${
                selectedAttendees.length
              })`}
              <RequiredField>*</RequiredField>
            </SectionLabel>
            {selectedTouchpointType ? (
              <GrayTypography variant="body2">
                {`1 ${t('components.touchpointManagement.attendee')}`}
              </GrayTypography>
            ) : null}
          </TinyBox>

          <SectionWrapper>
            {selectedAttendees?.length ? (
              <AttendeesInfoList
                attendees={selectedAttendees}
                variant={'remove'}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
              />
            ) : (
              <AnimatePresence>
                {!isSearchDropdownOpen ? (
                  <AttendeeSearchField
                    variant="initial"
                    selectedTouchpointType={selectedTouchpointType}
                    searchAttendeeQuery={searchAttendeeQuery}
                    isSubmitting={isSubmitting}
                    onOpenSearch={handleOpenSearch}
                  />
                ) : (
                  <motion.div>
                    <StyledCard
                      key="search-person-card"
                      ref={searchBoxRef}
                      data-testid="search-dropdown-card"
                      {...transitionVariant}
                    >
                      <StyledStack>
                        <AttendeeSearchField
                          variant="expand"
                          selectedTouchpointType={selectedTouchpointType}
                          searchAttendeeQuery={searchAttendeeQuery}
                          isSubmitting={isSubmitting}
                          onChangeSearchQuery={handleChangeSearchQuery}
                          onClearSearchQuery={handleClearSearchQuery}
                        />
                        {currentUser?.permissions?.common
                          ?.canSwitchSearchScope && (
                          <AttendeeScopeTabs
                            handleTabChange={handleTabChange}
                            tabIndex={tabIndex}
                          />
                        )}

                        <AttendeeSearchResults
                          fetchMorePersons={fetchMorePersons}
                          hasMorePerson={hasMorePerson}
                          loading={isLoadingSearch}
                          persons={personSearchResults}
                          filteredCount={filteredCount}
                          dialogHeight={dialogHeight}
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                          onCloseSearch={handleCloseSearch}
                        />
                      </StyledStack>
                    </StyledCard>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </SectionWrapper>
        </MediumStack>
      )}

      {mode === 'edit' && (
        <MediumStack>
          <SectionLabel variant="subtitle2">
            {`${t('components.touchpointManagement.attendee')} (${
              selectedAttendees.length
            })`}
          </SectionLabel>
          <AttendeeInfo
            variant={'none'}
            attendee={selectedAttendees[0]}
            showDivider={false}
          />
        </MediumStack>
      )}

      <SectionWrapper data-testid="organization-section">
        <OrganizationSection
          selectedAttendees={selectedAttendees}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          selectedOrganization={selectedOrganization}
          isSubmitting={isSubmitting}
          mode={mode}
        />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const TinyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}));

const GrayTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textTransform: 'lowercase',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,

  width: `calc(100% + ${theme.spacing(4)})`,
  left: `-${theme.spacing(2)}`,
  transform: `translateY(-${theme.spacing(2)})`,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));
